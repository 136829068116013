/**
 * @generated SignedSource<<46c6932768597b801200d2fbb287562b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviationType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "formTypes"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "deviationType",
  "variableName": "deviationType"
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviationType",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "formTypes",
    "variableName": "formTypes"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fields",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviationTypesOrganisationFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "DeviationTypesOrganisation",
            "kind": "LinkedField",
            "name": "deviationTypesOrganisation",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviationCategory",
                "kind": "LinkedField",
                "name": "deviationCategories",
                "plural": true,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "DeviationForm",
            "kind": "LinkedField",
            "name": "deviationForms",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": (v6/*: any*/),
            "kind": "FragmentSpread",
            "name": "DeviationFormCard_organisation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviationTypesOrganisationFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "DeviationTypesOrganisation",
            "kind": "LinkedField",
            "name": "deviationTypesOrganisation",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviationCategory",
                "kind": "LinkedField",
                "name": "deviationCategories",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "DeviationForm",
            "kind": "LinkedField",
            "name": "deviationForms",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasManual",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "edb988d6549ec852582c0479029db250",
    "id": null,
    "metadata": {},
    "name": "DeviationTypesOrganisationFormQuery",
    "operationKind": "query",
    "text": "query DeviationTypesOrganisationFormQuery(\n  $deviationType: String!\n  $formTypes: [String!]!\n) {\n  organisations {\n    databaseId\n    id\n    deviationTypesOrganisation(deviationType: $deviationType) {\n      deviationType\n      deviationCategories {\n        databaseId\n        id\n      }\n      id\n    }\n    deviationForms(deviationType: $deviationType, formTypes: $formTypes) {\n      formType\n      fields\n      id\n    }\n    ...DeviationFormCard_organisation_4v1Vrg\n  }\n}\n\nfragment DeviationFormCard_organisation_4v1Vrg on Organisation {\n  databaseId\n  hasManual\n  deviationTypesOrganisation(deviationType: $deviationType) {\n    deviationCategories {\n      databaseId\n      id\n    }\n    id\n  }\n  deviationForms(deviationType: $deviationType, formTypes: $formTypes) {\n    id\n    deviationType\n    formType\n    fields\n  }\n}\n"
  }
};
})();

node.hash = "9992841edb5a64e4192b6da27e0382ae";

module.exports = node;
