/**
 * @generated SignedSource<<9cb0af62f4885a58edde0116601dc96f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviationType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "deviationType",
    "variableName": "deviationType"
  },
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fields",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviationType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviationCategoryId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "field",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v13/*: any*/)
],
v15 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v9/*: any*/),
  (v13/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnonymousDeviationFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnonymousDeviationFormItems",
        "kind": "LinkedField",
        "name": "anonymousDeviationFormItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessUnit",
            "kind": "LinkedField",
            "name": "businessUnits",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataOption",
            "kind": "LinkedField",
            "name": "dataOptions",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataSubject",
            "kind": "LinkedField",
            "name": "dataSubjects",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationForm",
            "kind": "LinkedField",
            "name": "deviationForm",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationTypesOrganisation",
            "kind": "LinkedField",
            "name": "deviationTypesOrganisations",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviationCategory",
                "kind": "LinkedField",
                "name": "deviationCategories",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviationCategory",
                    "kind": "LinkedField",
                    "name": "deviationCategories",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviationCategory",
                        "kind": "LinkedField",
                        "name": "deviationCategories",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norms",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NormParagraph",
                "kind": "LinkedField",
                "name": "normParagraphs",
                "plural": true,
                "selections": (v10/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tooltip",
            "kind": "LinkedField",
            "name": "tooltips",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnonymousDeviationFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnonymousDeviationFormItems",
        "kind": "LinkedField",
        "name": "anonymousDeviationFormItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessUnit",
            "kind": "LinkedField",
            "name": "businessUnits",
            "plural": true,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataOption",
            "kind": "LinkedField",
            "name": "dataOptions",
            "plural": true,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DataSubject",
            "kind": "LinkedField",
            "name": "dataSubjects",
            "plural": true,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationForm",
            "kind": "LinkedField",
            "name": "deviationForm",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationTypesOrganisation",
            "kind": "LinkedField",
            "name": "deviationTypesOrganisations",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviationCategory",
                "kind": "LinkedField",
                "name": "deviationCategories",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviationCategory",
                    "kind": "LinkedField",
                    "name": "deviationCategories",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviationCategory",
                        "kind": "LinkedField",
                        "name": "deviationCategories",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          (v3/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norms",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NormParagraph",
                "kind": "LinkedField",
                "name": "normParagraphs",
                "plural": true,
                "selections": (v15/*: any*/),
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tooltip",
            "kind": "LinkedField",
            "name": "tooltips",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "17a101ce9d52f072000e9f5e58a1de0c",
    "id": null,
    "metadata": {},
    "name": "AnonymousDeviationFormQuery",
    "operationKind": "query",
    "text": "query AnonymousDeviationFormQuery(\n  $deviationType: String!\n  $uuid: String!\n) {\n  anonymousDeviationFormItems(deviationType: $deviationType, uuid: $uuid) {\n    businessUnits {\n      databaseId\n      name\n      id\n    }\n    dataOptions {\n      databaseId\n      name\n      id\n    }\n    dataSubjects {\n      databaseId\n      name\n      id\n    }\n    deviationForm {\n      databaseId\n      fields\n      formType\n      id\n    }\n    deviationTypesOrganisations {\n      databaseId\n      deviationType\n      deviationCategories {\n        databaseId\n        name\n        deviationCategories {\n          databaseId\n          deviationCategoryId\n          name\n          deviationCategories {\n            databaseId\n            deviationCategoryId\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    manualParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    norms {\n      databaseId\n      name\n      normParagraphs {\n        databaseId\n        name\n        number\n        id\n      }\n      id\n    }\n    tooltips {\n      description\n      field\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "2bea6ed2a01ae1c2c38992fbcacca126";

module.exports = node;
